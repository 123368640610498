import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <div className="footer small text-right">
    <Link to="/impressum/">
      Impressum
    </Link> &mdash; <Link to="/datenschutzerklaerung/">
      Datenschutzerklärung
    </Link>
  </div>
)

export default Footer
