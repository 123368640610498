import React from 'react'
import PropTypes from 'prop-types'

export default class Mailto extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      href: this.props.address.split('').reverse().join('') + ':otliam',
      text: this.props.address.split('').reverse().join('')
    };
  }

  componentDidMount() {
    this.setState({
      href: 'mailto:' + this.props.address,
      text: this.props.address
    })
  }

  render() {
    return (
      <a href={this.state.href}>{this.state.text}</a>
    )
  }
}

Mailto.propTypes = {
  address: PropTypes.node.isRequired,
}
